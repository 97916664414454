import React from "react"
import Navbar from "../components/Navbar/navbar"
import ResetCss from "../components/resetCSS"
import SEO from "../components/seo"
import Footer from "../components/Footer/footer"

const Dashboard = (props: any) => {
  return (
    <>
      <ResetCss />
      <Navbar />
      <SEO title="404: Not Found" />
      <Footer>
        Copyright &copy; Xiuming XU {new Date().getFullYear()}
      </Footer>
    </>
  )
}

export default Dashboard

